@tailwind base;
@tailwind components;
@tailwind utilities;

.rdp-cell button {
    margin: 1px;
    background-color: #33333333;
}

.rdp-cell button:hover:not([disabled]) {
    background-color: #411EA6 !important;
}

.rdp-nav button:hover:not([disabled]) {
    background-color: #411EA6 !important;
}