* {
  color: #FFFFFF;
  font-family: 'K2D', sans-serif;
}

.app-blue {
  background: #26163B;
}

.bg-1 {
  background: #181818;
}

.bg-2 {
  background: #1B163B;
}

.bg-3 {
  background: #3D3282;
}

.bg-4 {
  background: #2B1F39
}

.bg-5 {
  /* background: #411EA6; */
  background: #20117C;
}

.bg-6 {
  background: #40275F;
}

.bg-7 {
  background: #68419B;
}

.bg-8 {
  background: #411EA6;
}

.bg-gray {
  background: #242424;
}

.bg-green {
  background: #48B759
}

.bg-yellow {
  /* background: #F5E657; */
  background: #B7AC48;
}

.bg-gold {
  background: #9C8639;
}

.bg-red {
  background: #FF4141;
}

.bg-orange {
  background: #FF9C41;
}

.bg-purple {
  background: #7E69FF;
}

.bg-cyan {
  background: #0BC3CF;
}

.bg-lavender {
  background: #E8C3FF;
}

.text-3 {
  color: #3D3282
}

.text-gold {
  color: #9C8639;
}

.text-green {
  color: #0BCF89
}

.text-yellow {
  color: #B7AC48;
}

.text-purple {
  color: #7E69FF;
}

.text-red {
  color: #FF4B4B;
}

.text-cyan {
  color: #0BC3CF;
}

.text-lavender {
  color: #E8C3FF;
}


.opacAnimation {
  animation: animate 500ms ease-in-out;
}

@keyframes animate {
  0% {
    opacity: 0.5;
    transform: translateY(-5px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.opacAnimation2 {
  animation: animate2 200ms ease-in-out;
}

@keyframes animate2 {
  0% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}



body::-webkit-scrollbar {
  display: none
}
.scroll-hidden::-webkit-scrollbar {
  display: none
}

/* just for custom scrollbar*/
::-webkit-scrollbar {
  width: 5px;
  height: 2px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: #411EA6;
  border: 0px none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: #411EA6;
}

::-webkit-scrollbar-thumb:active {
  background: #411EA6;
}

::-webkit-scrollbar-track {
  background: #2B1F39;
  border: 0px none #411EA6;
  border-radius: 50px;
}

::-webkit-scrollbar-track:hover {
  background: #2B1F39;
}

::-webkit-scrollbar-track:active {
  background: #2B1F39;
}

::-webkit-scrollbar-corner {
  background: transparent;
}


button:disabled {
  background: rgba(75, 85, 99, 1);
}


.react-datepicker-wrapper{
  width: 100%;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 56px;
  height: 24px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch .slider::before {
  position: absolute;
  content: "";
  height: 25px;
  width: 25px;
  left: -2px;
  bottom: -1px;
  background-color: #C6C6C6;
  box-shadow: 0 0 1px #C6C6C6;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch input+.slider {
  background-color: #6B6B6B;
}
.switch input:checked+.slider {
  background-color: #296F33;
}

.switch input:checked+.slider::before {
  background-color: #51CD64;
}

.switch input:focus+.slider::before {
  box-shadow: 0 0 1px #51CD64;
}

.switch input:checked+.slider:before {
  -webkit-transform: translateX(34px);
  -ms-transform: translateX(34px);
  transform: translateX(34px);
}

/* Rounded sliders */
.switch .slider.round {
  border-radius: 34px;
  border: 1px solid #444;
}

.switch .slider.round:before {
  border-radius: 50%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  appearance: none;
  -moz-appearance: textfield;
}